import React,{useState,useEffect} from 'react'
import './Userdetails.css'
import store from '../../../mobx/store';
import { autorun} from 'mobx';
import {UserOutlined,SketchOutlined,ThunderboltOutlined} from '@ant-design/icons';
export default function Userdetails() {
    const [userisshow, setuserisshow] = useState(false);//控制用户详情页显示和隐藏
    const [userloginisshow, setuserloginisshow] = useState(false);//用于控制点击topbar后显示用户登录式样还是注册登录等式样
    // const [rechargeisshow, setrechargeisshow] = useState(false);//用于控制是否显示登录页面
    useEffect(() => {
        var unsubscribe=autorun(()=>{
            setuserisshow(store.loginisshow)
            setuserloginisshow(store.userisshow);
          })
        return () => {
            unsubscribe()
        };
    }, []);
    // 点了关闭按钮
    const userremovedown=()=>{
     store.loginisshowdown(false)
    }
    // 点了退出登录按钮
    const outlogindown=()=>{
        //删除所有登录信息
        localStorage.removeItem('aiwebuser');
        localStorage.removeItem('aiwebtoken');
        localStorage.removeItem('aiwebmemberdate');
        localStorage.removeItem('aiwebmemberintegral');
        localStorage.removeItem('aiwebuserphoto');
        store.userupdate({
          aiwebuser:'',
          aiwebtoken:'',
          aiwebmemberdate:'',
          aiwebmemberintegral:0,
          aiwebuserphoto:'',
        })
        store.userisshowdown(1)
         store.loginisshowdown(false)
    }
    //判断会员是否过期的函数
    function isCurrentTimeGreaterThan() {
        // 将给定的时间字符串转换为Date对象
        const givenTime = new Date(store.user.aiwebmemberdate);
        // 获取当前时间
        const now = new Date();
        const chinaNow = new Date(now.getTime());
        // 比较时间
        return chinaNow < givenTime;
    }
    const rechargedown=()=>{
        store.userisshowdown(3)
    }
  return (
    <div  style={{
        display: userloginisshow ==2 ? 'block' : 'none',
      }}>
    {/* 登录主体 */}
    {userisshow?
    <div className='userdetails'>
        <div className='loginbox'>
        <div className='remove' onClick={userremovedown}></div>
           <div className='imgbox'>
              <img src={require('../../../res/img/chat1.webp')}/>
          </div>
           {/* <div className='imgbox'>
              <img src={store.user.aiwebuserphoto}/>
          </div> */}
        <div className='spanbox'>
            <span>{store.user.aiwebuser.substring(0, 5) + '...' + store.user.aiwebuser.substring(store.user.aiwebuser.length - 5)}</span>
        </div>
        <div className='other'>
            <div className='one'>
                <div className='left'>
                    <div className='antdimgbox'>
                       <UserOutlined style={{ fontSize: '20px',color:'#fff'}}/>
                    </div>
                    <span>用户账号：</span>
                </div>
                <div className='right'>
                     <span>{store.user.aiwebuser}</span>
                </div>
            </div>
            <div className='two'>
                <div className='left'>
                    <div className='antdimgbox'>
                     <SketchOutlined style={{ fontSize: '22px',color:'#fff'}}/>
                    </div>
                    <span>会员到期：</span>
                </div>
                <div className='right'>
                     <span>{isCurrentTimeGreaterThan()?store.user.aiwebmemberdate.substring(0, 10) :'暂未开通'}</span>
                </div>
            </div>
            <div className='three'>
                <div className='left'>
                    <div className='antdimgbox'>
                      <ThunderboltOutlined style={{ fontSize: '20px',color:'#fff'}}/>
                    </div>
                    <span>用户算力：</span>
                </div>
                <div className='right' onClick={rechargedown}>
                    <span>{store.user.aiwebmemberintegral}</span>
                    <span>我要充值&gt;</span>
                </div>
            </div>
        </div>
        <button className='outlogin' onClick={outlogindown}>退出登录</button>
    </div>
    </div>
    :null}
    </div>
  )
}
