// 需要先安装npm i mobx@5
//如果不想取消订阅这种需要安装 yarn add mobx-react@5并重启
//如果不想取消订阅这种需要在index.js中配置标签
//如果用装饰器还要安装npm i @babel/core @babel/plugin-proposal-decorators @babel/preset-env
//如果用装饰器还要安装npm i customize-cra react-app-rewired
//如果用装饰器还要配置.babelrc和config-overrides.js文件
// import axios from 'axios'
import {observable,configure,action,runInAction} from 'mobx'
// 值never关闭mobx的严格模式always开始mobx的严格模式
configure({
    enforceActions:'always'
})
//装饰器写法
class Store{
    //控制收藏提示和联系客服的显示和隐藏
    @observable collectionisshow=false
    @action collectionisshowdown(collectionisshow){
       this.collectionisshow=collectionisshow
    }
    //控制收藏提示和联系客服的文字
    @observable collectiontext=''
    @action collectiontextdown(collectiontext){
       this.collectiontext=collectiontext
    }
    //用于控制topbar显示登录还是注册
    @observable userisshow=1
    @action userisshowdown(userisshow){
       this.userisshow=userisshow
     } 
     //控制用户登录页,用户详情页显示和隐藏
     @observable loginisshow=false
     @action loginisshowdown(loginisshow){
        this.loginisshow=loginisshow
     }
     //保存用户名和用户token
     @observable user={
        aiwebuser:'',
        aiwebtoken:'',
        aiwebmemberdate:'',
        aiwebmemberintegral:0,
        aiwebuserphoto:'',
     }
     @action userupdate(user){
        this.user=user
     }
}
export default new Store