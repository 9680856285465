// 收藏本站联系客服
import React,{useState,useEffect} from 'react'
import './Collection.css'
import store from '../../../mobx/store';
import { autorun, runInAction } from 'mobx';
export default function Collection() {
    const [collectionshow, setcollectionshow] = useState(false);//控制提示盒子显示和隐藏
    const [collectiontext, setcollectiontext] = useState('');//控制盒子的显示内容
    useEffect(() => {
        var unsubscribe=autorun(()=>{
            setcollectionshow(store.collectionisshow)
            setcollectiontext(store.collectiontext)
          })
        return () => {
            unsubscribe()
        };
    }, [store]);
  return (
    <div>
        {collectionshow?
        <div className={collectiontext=='按Ctrl+D收藏本站'?'tishihe':'tishihetwo'}>
          {collectiontext=='按Ctrl+D收藏本站'?
          <span>{collectiontext}</span>
          :
          <>
          <img src={require('../../../res/img/weixin.jpg')}></img>
          <span>邮箱:</span>
          <span>1026388835@qq.com</span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;无论是问题还是建议,欢迎您联系我们,我们看到后会第一时间给您回复</span>
          </>
        }
         </div>
         :null}
    </div>
  )
}
