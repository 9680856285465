import React,{useState,useEffect} from 'react'
import './Topbar.css'
import { useLocation,useNavigate } from "react-router-dom";
import store from '../../mobx/store';
import { autorun } from 'mobx';
import axios from 'axios'
import axiosurl from '../../axiosurl/axiosurl'
export default function Topbar() {
  const location = useLocation()
  const navigate=useNavigate()
  const [videocolor, setvideocolor] = useState(false);//控制视频背景颜色点击变化
  const [drawcolor, setdrawcolor] = useState(false);//控制绘画背景颜色点击变化
  const [chatcolor, setchatcolor] = useState(false);//控制对话背景颜色点击变化
  const [musiccolor, setmusiccolor] = useState(false);//控制对话背景颜色点击变化
  const [userisshow, setuserisshow] = useState(false);//用于控制topbar显示用户登录式样还是注册登录式样
  useEffect(() => {
           console.log(location.pathname);//查看当前在哪个页面
           if(location.pathname=='/'){
            setdrawcolor(true)
           }else if(location.pathname=='/chatai'){
            setchatcolor(true)
           }else if(location.pathname=='/draw'){
            setdrawcolor(true)
           }else if(location.pathname=='/video'){
            setvideocolor(true)
           }else if(location.pathname=='/music'){
            setmusiccolor(true)
           }
             const tokenvalue =localStorage.getItem('aiwebtoken')
            //  console.log(tokenvalue);
              axios.defaults.headers["Authorization"] = tokenvalue;
              axios.post(`${axiosurl}/inspecttoken`,
           )
           .then(res => {
            if(res.data.message=='无效的token'){
              //清除数据
              localStorage.removeItem('aiwebuser');
              localStorage.removeItem('aiwebtoken');
              localStorage.removeItem('aiwebmemberdate');
              localStorage.removeItem('aiwebmemberintegral');
              localStorage.removeItem('aiwebuserphoto');
              store.userupdate({
                aiwebuser:'',
                aiwebtoken:'',
                aiwebmemberdate:'',
                aiwebmemberintegral:0,
                aiwebuserphoto:'',
              })
              store.userisshowdown(1)
            }else{
              //拿到历史存储的用户名
              const aiwebuser=localStorage.getItem('aiwebuser')
              const aiwebmemberdate=localStorage.getItem('aiwebmemberdate')
              const aiwebmemberintegral=localStorage.getItem('aiwebmemberintegral')
              const aiwebuserphoto=localStorage.getItem('aiwebuserphoto')
              // console.log(aiwebuser,aiwebmemberdate,aiwebmemberintegral,aiwebuserphoto);
              if(aiwebuser==null || aiwebmemberdate==null || aiwebmemberintegral==null || aiwebuserphoto==null){//如果用户名丢失
                //清除数据
               localStorage.removeItem('aiwebuser');
              localStorage.removeItem('aiwebtoken');
              localStorage.removeItem('aiwebmemberdate');
              localStorage.removeItem('aiwebmemberintegral');
              localStorage.removeItem('aiwebuserphoto');
              store.userupdate({
                aiwebuser:'',
                aiwebtoken:'',
                aiwebmemberdate:'',
                aiwebmemberintegral:0,
                aiwebuserphoto:'',
              })
              store.userisshowdown(1)
              }else{//成功登录
                // 重新保存新获取的token
                localStorage.setItem('aiwebtoken', res.data.token);
                store.userupdate({
                  aiwebuser:aiwebuser,
                  aiwebtoken:res.data.token,
                  aiwebmemberdate:aiwebmemberdate,
                  aiwebmemberintegral:aiwebmemberintegral,
                  aiwebuserphoto:aiwebuserphoto,
                })
                store.userisshowdown(2)
              }
            } 
           }).catch(error=>{
            if(error.message=='Network Error'){
              console.log('网络问题');
            }else if (error.response && error.response.status === 429) {
              console.log('请求频率过高');
              // 可以在这里添加显示提示信息或者其他处理逻辑
            }else{
              console.log(error);
            }
           })
            // 订阅变化控制topbar显示登录还是注册
        const disposer = autorun(() => {
          setuserisshow(store.userisshow);
      });
      // 清理订阅
      return () => disposer();
  }, []);
  //点击了视频按钮
  const videodown=()=>{
    if(videocolor==true){
      return
    }
    //控制点击后背景颜色切换
    setmusiccolor(false)
    setvideocolor(true)
    setdrawcolor(false)
    setchatcolor(false)
    navigate('/video')
  }
    //点击了绘画按钮
    const drawdown=()=>{
      if(drawcolor==true){
        return
      }
      setmusiccolor(false)
    setvideocolor(false)
    setdrawcolor(true)
    setchatcolor(false)
    navigate('/draw')
    }
      //点击了对话按钮
  const chatdown=()=>{
    if(chatcolor==true){
      return
    }
    setmusiccolor(false)
    setvideocolor(false)
    setdrawcolor(false)
    setchatcolor(true)
    navigate('/chatai')
  }
     //点击了音乐按钮
     const musicdown=()=>{
      if(musiccolor==true){
        return
      }
      setvideocolor(false)
      setdrawcolor(false)
      setchatcolor(false)
      setmusiccolor(true)
      navigate('/music')
    }
    let usertimeout;
    //用户点了收藏按钮
    const collectiondown=()=>{
      clearTimeout(usertimeout)
      store.collectionisshowdown(false)
      store.collectionisshowdown(true)
      store.collectiontextdown('按Ctrl+D收藏本站')
      usertimeout=setTimeout(() => {
        store.collectionisshowdown(false)
      }, 3000);
    }
     //用户点了联系客服
  const servicedown=()=>{
    clearTimeout(usertimeout)
    store.collectionisshowdown(false)
    store.collectionisshowdown(true)
    store.collectiontextdown('邮箱1026388835@qq.com')
    usertimeout=setTimeout(() => {
      store.collectionisshowdown(false)
    }, 6000);
  }
  //用户点了注册登录按钮
  const logindown=()=>{
    store.loginisshowdown(true)
  }
  //用户点了用户详情按钮
  const userdown=()=>{
    store.loginisshowdown(true)
  }
  return (
    <div className='topbar'>
         {/* 左 */}
         <div className='left'>
          <img src={require('../../res/img/tubiao.png')}></img>
          <header className="Sora AI">
            <h1>Sora AI</h1>
          </header>
         </div>
         {/* 中 */}
         <div className='middle'>
          <div className='middleinbox' onClick={videodown}>
            <div className={videocolor?'imghe one':'imghe two'}>
               <img src={require('../../res/img/baishipin.png')}></img>
            </div>
            <h2>AI视频</h2>
          </div>
          <div className='middleinbox' onClick={musicdown}>
            <div className={musiccolor?'imghe one':'imghe two'}>
               <img src={require('../../res/img/baiyinyue.png')}></img>
            </div>
            <h2>AI音乐</h2>
          </div>
          <div className='middleinbox' onClick={drawdown}>
            <div className={drawcolor?'imghe one':'imghe two'}>
               <img src={require('../../res/img/baisheji.png')}></img>
            </div>
            <h2>AI绘画</h2>
          </div>
          <div className='middleinbox' onClick={chatdown}>
            <div className={chatcolor?'imghe one':'imghe two'}>
               <img src={require('../../res/img/baibiancheng.png')}></img>
            </div>
            <h2>AI对话</h2>
          </div>
         </div>
         {/* 右 */}
         <div className='right'>
         <button className='two' onClick={collectiondown}>
            <div className='imghe'>
            <img src={require('../../res/img/wujiaoxing.png')}></img>
            </div>
            <span>收藏本站</span>
          </button>
          <button className='one' onClick={servicedown}>
            <div className='imghe'>
            <img src={require('../../res/img/lanchat.png')}></img>
            </div>
            <span>联系客服</span>
          </button>
          {userisshow==1?
          <div className='three' onClick={logindown}>
          <div className='imghe'>
          <img src={require('../../res/img/userimg2.png')}></img>
          </div>
          <span>登录</span>
        </div>
          :
          <div className='threetwo' onClick={userdown}>
            <div className='imghe'>
                <img src={require('../../res/img/chat1.webp')}/>
            </div>
            <span>{store.user.aiwebuser.substring(0, 1) + '...' + store.user.aiwebuser.substring(store.user.aiwebuser.length - 1)}</span>
          </div>
          }
         </div>
    </div>
  )
}
