import './App.css';
import React,{useState,useEffect} from 'react'
import { BrowserRouter } from "react-router-dom";
import MRouter from "./router/Router";
import Topbar from "./views/Topbar/Topbar";
import Login from './views/Topbar/login/Login';
import Recharge from './views/Topbar/recharge/Recharge'
import Rechargeissue from './views/Topbar/rechargeissue/Rechargeissue'
import Userdetails from './views/Topbar/userdetails/Userdetails'
import Collection from './views/Topbar/collection/Collection';
import { SocketProvider } from './socketio/Socketio';
function App() {
  //useEffect为了适配ios手机浏览器的高度工具栏遮挡问题,所有vh下面加类似height: calc(var(--vh, 1vh) * 100 - 70px);
  useEffect(() => {
    const setVh = () => {
      // 获取视窗高度并设置 --vh 变量
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // 初始化设置
    setVh();

    // 监听窗口变化,重新设置 --vh 变量
    window.addEventListener('resize', setVh);

    // 清除监听器
    return () => window.removeEventListener('resize', setVh);
  }, []);
  return (
    <div className="App">
      <SocketProvider>
    <BrowserRouter>
     <Topbar></Topbar>
     <MRouter></MRouter>
     <Login></Login>
     <Userdetails></Userdetails>
     <Recharge></Recharge>
     <Rechargeissue></Rechargeissue>
     <Collection></Collection>
    </BrowserRouter>
    </SocketProvider>
    </div>
  );
}

export default App;
