//socket.io连接配置，需要在跟组件配置此次是APP.js，然后组件中使用
import React, { createContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import store from '../mobx/store';
import { autorun } from 'mobx';

let SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [updates, setupdates] = useState(null);

  // 用于监听是否登录
  useEffect(() => {
    const unsubscribe = autorun(() => {
      setupdates(store.user.aiwebtoken); // 用于 store.user.aiwebtoken 改变时更新
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // socket.io 主连接的 effect
  useEffect(() => {
    // 如果没有 token 则不执行连接 socket.io 等操作
    if (localStorage.getItem('aiwebtoken') == null || !updates) {
      return;
    }
    console.log('执行了');
    // const newSocket = io('http://localhost:3008', {
    //   withCredentials: true, // 允许跨域请求携带 cookie
    //   query: { // 刚开始连接时就能发送参数
    //     token: localStorage.getItem('aiwebtoken')
    //   }
    // });
    const newSocket = io('wss://mjchatgpt.com', {
      path: '/socket.io', // 匹配 Nginx 配置的路径
      withCredentials: true, // 确保跨域请求携带凭证（如cookie）
      transports: ['websocket'], // 强制使用 WebSocket 协议
      query: {
        token: localStorage.getItem('aiwebtoken') // 传递 token 作为查询参数
      },
      secure: true, // 确保在 HTTPS 环境下连接
      reconnection: true, // 自动重连
      reconnectionAttempts: 5, // 重试次数
      reconnectionDelay: 1000, // 重试延迟时间（毫秒）
      timeout: 20000, // 连接超时时间（毫秒）
    });
    console.log(newSocket);
    setSocket(newSocket);
    newSocket.on('connect_error', (err) => {
      console.error('Connection Error:', err);
  });
  
  newSocket.on('error', (err) => {
      console.error('Socket Error:', err);
  });
  
  newSocket.on('disconnect', (reason) => {
      console.log('Disconnected:', reason);
  });
    return () => {
        if (newSocket) {
            newSocket.close();
          }
    };
  }, [updates]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContext;